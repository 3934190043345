.messenger-main {
    height: calc(100vh - 250px);
}

.msg-text-box {
    resize: none;
}

.chat-top {
    height: 100%;
    overflow-y: auto;
}

/* width */
.messenger-main ::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.messenger-main ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.messenger-main ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}

/* Handle on hover */
.messenger-main ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.prev-msg-btn {
    border: 0;
    padding: 10px 26px;
    border-radius: 20px;
}

.no-msgs {
    width: 100px;
    opacity: 0.5;
}
