#featured-jobs-carousel {
    margin: 30px;
}

#featured-jobs-section {
    border-top: 1px solid #fcb333;
    border-bottom: 1px solid #fcb333;
    background: url('https://imejob.fra1.cdn.digitaloceanspaces.com/cms/production/Top_image_slider_01_8f6a4f0e5d.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 400px;
}

.right .card#set-job-alert {
    background: url(https://imejob.fra1.cdn.digitaloceanspaces.com/cms/production/Set_Job_Alert_01_b017c63547.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.right .card#get-hr-support {
    background: url(https://imejob.fra1.cdn.digitaloceanspaces.com/cms/production/HR_Support_01_60762fe7a4.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.right .card#post-free-job {
    background: url(https://imejob.fra1.cdn.digitaloceanspaces.com/cms/production/Post_Free_Job_01_0f28c411e1.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.resend-btn {
    border: none;
    background: transparent;
}

.carousel_white_text {
    color: white !important;
}

.font-13 {
    font-size: 13px;
}

.otp-div input:nth-child(4) {
    margin-right: 0 !important;
}

.otp-div input {
    width: 40px !important;
    height: 40px !important;
}

.forgot-password-link,
.back-link {
    text-decoration: none;
    font-size: 15px;
}

.text-decoration-none {
    text-decoration: none;
}

.password-helper {
    font-size: 13px;
}

.widget-icon {
    color: #a09000;
    font-size: 20px;
    background-color: rgba(252, 181, 56, 0.25);
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
    border: 1px solid #f5dd10;
}

.widget-num {
    color: #6c757d;
}

.admin-sidebar {
    width: 260px;
    position: fixed;
    background: #eeeeee;
    margin-top: 77px;
    z-index: 10;
    bottom: 0;
    top: 0;
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    padding: 15px;
}

.admin-container {
    padding: 10px 20px;
}

.admin-side-bar-item {
    font-size: 14px;
}

.admin-sidebar .nav-link {
    font-size: 15px;
}

@media only screen and (max-width: 992px) {
    .admin-container {
        width: 100%;
    }

    .admin-sidebar {
        left: -260px;
    }

    .avatar-name {
        margin-top: 0.5rem !important;
        margin-left: 7rem !important;
    }

    .profile-menu-ul {
        margin-top: 0.5rem !important;
    }
}

.form-switch .form-check-input {
    width: 4em;
    height: 2em;
}

.employer-assignment-search .form-check-search-input {
    width: 6em !important;
    height: 3em !important;
    margin-top: 0.5rem !important;
}

.form-switch .panel-employer {
    height: 1.6em;
}

.page-preloader {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background: #ffffff54;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.bg-soft-danger {
    background-color: #ffd1d1 !important;
}

.bg-soft-primary {
    background-color: #bbdef4 !important;
}

.bg-soft-success {
    background-color: #ccffbd !important;
}

.job-list-div .job-pos {
    flex: 2 !important;
}

.pending-approval {
    font-size: 10px !important;
}

#apply-job .card .company-name {
    font-size: 12px !important;
}

#apply-job .card .job-details {
    font-size: 14px !important;
}

#apply-job .resume-card {
    max-height: 50px;
    min-height: 50px;
}

#apply-job .resume-label {
    font-size: 13px;
}

.or-tag {
    font-size: 13px;
}

.btn-history {
    background: #dae3ca !important;
}

.job-active {
    background-color: #e6e6e6;
}

.back-modal .modal-content {
    background-color: #fff !important;
}

button:disabled {
    cursor: not-allowed !important;
}

.company-logo {
    width: 95px;
}

.company-initials {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #badef3;
    color: #386f9d;
    margin-right: 1.5rem;
    font-size: 30px;
    font-weight: 500;
}

#edit-modals .modal-header {
    padding: 1rem 1rem;
}

.work-history .designation {
    font-size: 1.7rem;
}

.send-link-btn {
    height: 30px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 2px;
}

.general-btn {
    height: 100%;
    width: 100%;
    background: #f5dd10;
    border: 1px solid #000;
    border-radius: 5px;
}

.margin-200-px {
    margin-top: 200px;
}

a {
    cursor: pointer;
}

.web-logo {
    width: 12rem;
}

.avatar-img {
    width: 65px;
    height: 65px;
}

.custom-badge {
    font-size: 1rem;
    padding: 5px 20px;
    background: #41a543;
    color: #fff;
    border-radius: 10px;
}

.status-font {
    font-size: 13px;
}

.welcome-avatar {
    width: 40px;
    height: 40px;
}

.pill {
    padding: 5px 15px;
    border-radius: 5px;
    color: #fff;
}

.pill-success {
    background-color: #188754;
}

.pill-warning {
    background-color: #f6dd0f;
    color: #000 !important;
}

.pill-primary {
    background-color: #0d6efd;
}

.pill-secondary {
    background-color: #6c747d;
}

.pill-info {
    background-color: #0dc9f0;
    color: #000 !important;
}

.pill-dark {
    background-color: #212429;
}

.pill-light {
    background-color: #f9f9fb;
    color: #000 !important;
}

.btn-util {
    font-size: 12px;
}

.best-fit-div {
    padding: 5px 30px;
    border: 1px solid #000;
    color: #fff;
    background: #d4a2a2;
    font-size: 13px;
}

/* Custom Button CSS */
.ime-btn {
    padding: 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
    border: 0;
    text-decoration: none;
}

.ime-btn:hover {
    color: unset !important;
}

.ime-btn span {
    font-size: 1.1rem;
}

.ime-btn-primary-soft {
    background: #badef3;
    color: #386f9d;
}

.ime-btn-white-soft {
    background: #fff;
    color: #000;
    border: 1px solid #000;
}

.ime-btn-light-soft {
    background: #e9e9e9;
    color: #000;
}

.ime-btn-warning-soft {
    background: #fdecb6;
    color: #f47317;
}

.ime-btn-success-soft {
    background: #ccffbd;
    color: #019400;
}

.ime-btn-danger-soft {
    background: #ffc7b8;
    color: #e02b26;
}

/* Avatar */
.avatar {
    height: 3rem;
    width: 3rem;
    position: relative;
    display: inline-block !important;
}

.avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.avatar-group {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.avatar-group > li {
    position: relative;
}
.avatar-group > li:not(:last-child) {
    margin-right: -0.8rem;
}
.avatar-group .avatar-img {
    border: 2px solid #fff;
}

.avatar .avatar-name {
    margin-left: 7px;
}

.avatar-xs {
    height: 2.1875rem;
    width: 2.1875rem;
}

.avatar-sm {
    height: 2.5rem;
    width: 2.5rem;
}

.avatar-lg {
    height: 4rem;
    width: 4rem;
}

.avatar-xl {
    height: 5.125rem;
    width: 5.125rem;
}

.avatar-xxl {
    height: 5.125rem;
    width: 5.125rem;
}
@media (min-width: 768px) {
    .avatar-xxl {
        width: 8rem;
        height: 8rem;
    }
}

.avatar-xxxl {
    height: 8rem;
    width: 8rem;
}
@media (min-width: 768px) {
    .avatar-xxxl {
        width: 11rem;
        height: 11rem;
    }
}

/* Text CSS */
.text-purple,
.text-purple a {
    color: #1f05bf !important;
    text-decoration: none;
}

.ime-text-warning {
    color: #f47317;
}

/* Alert CSS */
.ime-alert-primary {
    background: #bbdef4;
    color: #004788;
    font-weight: 700;
}

.ime-alert-success {
    background: #ccffbd;
    color: #019400;
    font-weight: 700;
}

.ime-alert-danger {
    background: #ffc7b8;
    color: #e02b26;
    font-weight: 700;
}

/* Badge CSS */
.ime-badge {
    padding: 5px 20px;
    font-weight: 600;
    border-radius: 10px;
    display: inline-block;
}

.ime-badge-primary {
    background: #bbdef4;
    color: #004788;
}

.ime-badge-success {
    background: #ccffbd;
    color: #019400;
}

.ime-badge-danger {
    background: #ffc7b8;
    color: #e02b26;
}

.ime-badge-light {
    color: #8e887a;
    background-color: #f8f6f2;
}

/* Backgrounds */
.ime-bg-light {
    background-color: #f9fafc;
}

.avatar-name {
    margin-left: 5.5rem;
    margin-top: -0.25rem;
}

.welcome-avatar-profile {
    float: left !important;
    margin-top: -1rem;
}
.profile-menu-ul {
    padding: 20px 10px 0px 10px;
    width: 250px;
    height: auto;
    left: auto !important;
    right: 0;
}
.profile-menu-ul .row-box {
    display: flex;
}

.name-box {
    margin-top: -5px;
}

.profile-menu-ul .row-box .image-box {
    width: 50px;
}
.profile-menu-ul .row-box h1 {
    font-size: 15px;
}
.profile-menu-ul .row-box span.email-address {
    font-size: 12px;
    color: rgb(50, 49, 49);
}
.profile-menu-ul ul {
    padding: 5px 0;
    margin: 0;
    list-style: none;
}

.profile-sub-menu-ul li a:hover {
    background-color: #ddd;
}

.profile-link {
    text-decoration: none;
    color: #000;
}
.logout-btn:hover {
    cursor: pointer;
    background-color: #ddd;
}

#about-us #hero-right img {
    border-radius: 0.8rem;
}

#about-us #employer-right {
    padding-left: 135px;
}

@media (max-width: 992px) {
    #about-us #employer-right {
        padding-left: 0;
    }
}

#admin-login,
#main-login {
    background-image: url(https://imejob.fra1.cdn.digitaloceanspaces.com/cms/production/IME_Login_page_image_d5d87bcd75.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    color: white;
}

#login-page-box {
    height: calc(100vh - 84px);
}

#chatbot-btn {
    bottom: 2rem;
    border-radius: 50%;
    height: 6.5rem;
    width: 6.5rem;
}

#chatbot-window {
    bottom: 10rem;
    min-height: 30rem;
    width: 30rem;
    border-radius: 1.5rem;
    transition: visibility 0s, opacity 0.3s linear;
}

.chatbot-show {
    visibility: visible;
    opacity: 1;
    z-index: 100000;
}

.chatbot-hide {
    visibility: none;
    opacity: 0;
    z-index: -1;
}

#chatbot-header {
    border-radius: 1.5rem 1.5rem 0 0;
    background: #fdecb6;
}

#chatbot-window,
#chatbot-btn {
    position: fixed;
    right: 2rem;
}

#chatbot-btn {
    z-index: 100000;
}

#chatbot-window #message {
    max-width: 78%;
    border-radius: 0.6rem 0.6rem 0.6rem 3rem;
    background: rgb(220, 219, 219);
}

.stats-container {
    flex: 3;
    align-items: flex-end;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}

.stats-item {
    flex: 1 0 10%;
    margin: 5px;
    align-items: center;
    justify-content: center;
}

.job-dashboard-list .job-pos {
    flex: 1 !important;
}

footer {
    position: absolute;
    width: 100%;
    background: black;
    border-top: 1px solid #999999;
    font-family: 'Inter';
    font-size: 1.8rem;
}

footer .slug_box {
    position: relative;
    border-bottom: 0.1rem solid #ffffff;
    padding: 2% 0% 2% 5%;
}

footer .copyright p {
    line-height: 50px;
    color: #fbfbfb;
    display: flex;
    justify-content: center;
}
footer .slug_column {
    font-style: normal;
    display: flex;
    color: #ffffff;
}

footer .slug_column > a {
    color: #ffffff;
    text-decoration: none;
}

.disabled {
    pointer-events: none;
    cursor: pointer;
}

.detail-our-service {
    border-bottom: 0.1rem solid;
    padding: 1rem 0 0.5rem 0;
}

.wrap-our-service {
    background: #386f9d;
    padding: 3rem 2rem 3rem;
    border-radius: 3rem;
    color: white;
}

.modal-dialog-buy-service {
    max-width: 700px !important;
}

.requestDemoBtn {
    background: #f47217;
    border-radius: 15px !important;
    color: white;
    font-size: 1.8rem !important;
    text-transform: none !important;
}

.hiringSolContent {
    font-size: 4rem;
    padding-bottom: 8rem;
}

.ourSolutionHeading {
    font-size: 4rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.nonHiringSolContent {
    font-size: 3rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

@media (max-width: 768px) {
    .hiringSolContent .imageContainer {
        display: none;
    }
    .buyServiceColumn {
        display: none;
    }

    .contactUsColumn {
        display: flex;
        justify-content: flex-start !important;
    }

    .hiringSolContent div,
    .nonHiringSolContent div {
        justify-content: center !important;
        text-align: center;
    }
    .leftCard,
    #frequentlyAskedAccordIn,
    .contactUsClass {
        margin-bottom: 2rem;
    }
}

.buyServiceRow {
    border: 2px solid black;
    height: auto;
    padding: 2rem;
}

.ourServiceCard {
    background-color: #e19865;
}

.buyServiceColumn {
    height: 75rem;
}

.buyServiceCards {
    border-radius: 3.5rem !important;
    height: 100%;
}

.buyServiceImage {
    padding-top: 12rem;
}

.contactUsClass,
.contactUsClass:hover {
    color: #f47217;
    text-decoration: none;
}

.buyServiceEmployerCard {
    border-radius: 3.5rem !important;
    height: 100%;
}

.jobSeekerCard {
    background-color: #f47217;
}

.accordInBox .accordion-button:not(.collapsed) {
    background-color: #e19865;
    color: #ffffff;
}

.marginBottom {
    margin-bottom: -1.5rem;
}

.innerBuyServiceRow {
    flex: 1;
}

.buyCardRow {
    flex: 2;
}

.textRed {
    color: #f47217;
}

.line {
    width: 3rem;
    padding-top: 0.5rem;
}

.lineRed {
    border-bottom: 0.3rem solid #f47217;
}

.lineWhite {
    border-bottom: 0.3rem solid white;
}

.buyNowEmployer,
.buyNowEmployer:hover {
    background: #f47217;
    color: white !important;
}

.buyNowJobSeeker,
.buyNowJobSeeker:hover {
    background: white;
    color: #000 !important;
}

.buyNowBtn {
    border-radius: 15px !important;
    color: white;
    font-weight: 600;
    font-size: 2rem !important;
    text-transform: none !important;
}

#frequentlyAskedAccordIn .accordion-item:first-of-type .accordion-button,
#frequentlyAskedAccordIn .accordion-item:first-of-type {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

#frequentlyAskedAccordIn .accordion-item:last-of-type {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

#frequentlyAskedAccordIn h2 {
    padding-bottom: 0 !important;
}

#frequentlyAskedAccordIn .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

#frequentlyAskedAccordIn .accordion-button {
    font-weight: 500 !important;
}

.serviceHeading {
    font-size: 3.2rem;
}

.ourServiceDesc {
    font-size: 2rem !important;
    font-weight: 400 !important;
}

.ourServiceHeading {
    font-size: 3.5rem;
}

#hiringSolutions p,
#frequentlyAskedAccordIn .accordion-body {
    color: #999999 !important;
}

@media (min-width: 993px) {
    #buyServices {
        margin: 5rem 20rem;
    }
    .leftCard {
        padding-left: 30px;
        padding-right: 5px;
    }

    .rightCard {
        padding-left: 35px;
    }

    .ourServiceHeading {
        font-size: 5rem;
    }

    .subCardRightColumn {
        height: 60rem;
        /* width: 45%;
        margin-left: 2.2rem; */
    }
}

@media (min-width: 992px) {
    .w-lg-300px {
        width: 300px !important;
    }

    .w-lg-400px {
        width: 400px !important;
    }

    .mw-lg-400px {
        max-width: 400px !important;
    }
}

@media (min-width: 1200px) {
    .w-xl-400px {
        width: 400px !important;
    }

    .w-xl-500px {
        width: 500px !important;
    }
}

.h-48 {
    height: 48% !important;
}

.h-4 {
    height: 4% !important;
}

.bg-light-chat-info {
    background-color: #eff0fe !important;
}

.bg-light-chat-primary {
    background-color: #ddf8fc !important;
}

.flex-stack {
    justify-content: space-between;
    align-items: center;
}
