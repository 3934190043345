@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,600;1,100;1,200;1,300&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');

html {
    font-family: 'Poppins', sans-serif;
    font-size: 62.5%;
    color: #686781;
}

.fs-16 {
    font-size: 1.6rem;
}
.bg-light {
    background-color: #fff !important;
    border-bottom: 1px solid #ccc;
}
.navbar {
    padding: 1.5rem 2.5rem;
}
.navbar-light .navbar-nav .nav-link {
    color: #171728;
    font-size: 1.6rem;
    font-weight: 400;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #f6931c;
}
.navbar-brand {
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-right: 4rem;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}
.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}
.btn {
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    font-size: 1.6rem;
}
.hero {
    background: #f2f5ff;
}
.hero h1 {
    font-size: 4rem;
    font-weight: 500 !important;
}

.hero p {
    font-size: 1.8rem;
}

.hero a {
    font-size: 1.8rem;
}

.mrl-0 {
    margin-left: 0;
    margin-right: 0;
}

.item-custom {
    width: 80%;
    margin: 0 auto;
    padding: 3rem 0;
}
.search-con-company {
    position: relative;
    width: 100%;
    min-height: 2rem;
}

.search-con {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 3;
    top: -6.5rem;
    background: #fff;
    padding: 0.8rem;
    border-radius: 1.5rem;
    padding: 1.5rem 2rem;
    border-style: solid;
    border-width: 1px;
    border-color: #e4e4ed;
    border-radius: 2rem;
    background-color: #fff;
    box-shadow: 0 0.7rem 1.4rem 0 rgba(8, 15, 52, 0.03);
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}
.search-con h2 {
    font-size: 2.6rem;
    padding: 0rem 0rem 0.5rem 0rem;
}
.search-row {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    width: 100%;
}
.search-row:hover {
    border-color: #0061ff;
}
.search-btn {
    font-size: 1.6rem;
    padding: 1.6rem 4rem;
    border-radius: 0rem 0.4rem 0.4rem 0rem;
}

.search-btn:hover {
    background: #171728;
}
.search {
    flex: 2;
}

.search-ot {
    flex: 1;
}

.company {
    display: block;
    position: relative;
    margin-top: 4rem;
}

.skill {
    border: none;
    padding: 1.5rem;
    font-size: 1.6rem;
    border-right: 0.1rem solid #ccc;
    border-radius: 0;
}
.location {
    border: none;
    padding: 0.8rem;
    border-radius: 0;
    padding: 1.5rem;
    font-size: 1.6rem;
}
.skill-area {
    width: 35%;
}
.skill-area {
    align-items: center;
}
.skill-area i {
    font-size: 2rem;
    color: #0061ff;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 3rem;
}

.location-area {
    width: 25%;
}
.salary-area,
.location-area {
    align-items: center;
}
.salary-area {
    width: 20%;
}
.location-area i {
    font-size: 2rem;
    color: #0061ff;
    margin-left: 1rem;
    margin-right: 1rem;
}
.search-area {
    width: 15%;
    text-align: right;
}

.skill-area:focus {
    outline: none;
    box-shadow: none;
}

.form-control:focus {
    box-shadow: none;
}
.form-select:focus {
    box-shadow: none;
}

.py-5 {
    padding-top: 5rem !important;
    padding-bottom: 6rem !important;
}
.division {
    margin-top: 8rem;
}

.left-panel {
    margin-left: 5%;
}
.right-panel {
    margin-left: 5%;
    margin-right: 5%;
}
.left-panel h2 {
    color: #171728;
    font-size: 2.6rem;
    line-height: 1.438em;
    font-weight: 700;
    padding-bottom: 1.5rem;
}

.right-panel h2 {
    color: #171728;
    font-size: 2.6rem;
    line-height: 1.438em;
    font-weight: 700;
    padding-bottom: 1.5rem;
}

.oppening {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ffde6a;
    background-color: #fffefa;
    color: #686781;
    width: 100%;
    height: auto;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
}
.right .card {
    padding: 3.5rem;
    margin-bottom: 1.5rem;
    align-items: center;
    border-radius: 0.5rem;
}

.right .card i {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 5rem;
    background-color: #f2f5ff;
    color: #386f9d;
    width: auto;
    margin-right: auto;
    border-radius: 0.5rem;
    margin-left: auto;
}
.right .card p {
    font-size: 1.6rem;
    color: white;
}

.right .card h2 {
    color: white;
}

.right .card button,
.right .card a {
    font-size: 1.2rem;
}
.com-logo {
    margin-right: 1.5rem;
}

.oppening-con {
    text-align: left;
    margin-right: auto;
}
.oppening-con h2 {
    display: block;
    font-size: 2.2rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
}
.oppening-con h3 {
    display: block;
    font-size: 1.8rem;
}
.oppening-con p {
    display: block;
    font-size: 1.6rem;
}
.mobile-on {
    display: none;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.6rem !important;
}

@media (max-width: 1024px) {
    html {
        font-size: 55.5%;
    }
    .search-area {
        width: 25%;
    }
    .search-con {
        top: -4.5rem;
    }
    .company {
        margin-top: 9rem;
    }
    .division {
        margin-top: 8rem;
    }
}

@media (max-width: 768px) {
    html {
        font-size: 45.5%;
    }
    .owl-dots {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .company {
        margin-top: 11rem;
    }
}
@media (max-width: 767px) {
    html {
        font-size: 45%;
    }
    .navbar-toggler-icon {
        font-size: 3.6rem;
        margin-top: 0.3rem;
    }
    .navbar-brand {
        font-size: 3rem;
    }
    .navbar-toggler-icon {
        width: 3.5rem;
        height: 3.5rem;
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }

    .navbar-nav {
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        font-size: 2.1rem;
        padding-left: 0rem;
    }
    .mobile-on {
        display: block;
        margin-top: 1rem;
        font-size: 2rem;
    }
    .mobile-off {
        display: none;
    }
    .btn {
        font-size: 2.1rem;
        padding: 0.7rem 3.5rem;
    }
    .oppening {
        flex-direction: column;
    }
    .oppening-con {
        text-align: center;
        width: 100%;
        margin: 0.5rem 0;
        font-size: 1.6rem !important;
    }
}

@media (max-width: 580px) {
    .search-row {
        display: flex;
        flex-direction: column;
        padding: 0.8rem;
        border: none;
    }

    .skill-area {
        width: 100%;
    }

    .salary-area,
    .location-area {
        width: 100%;
    }
    .search-area {
        width: 100%;
        text-align: end;
    }

    .skill {
        border: 1px solid #ccc;
    }
    .location {
        border: 1px solid #ccc;
    }
    .salary-area,
    .location-area {
        margin: 1.5rem 0;
    }
    .search-btn {
        width: 100%;
    }
    .division {
        margin-top: 25rem;
    }
    .com-logo {
        width: 19%;
    }
    .navbar-toggler-icon {
        width: 2.5rem;
        height: 2.5rem;
    }
    .oppening-con h2 {
        font-size: 2rem;
    }
    .skill-area i {
        display: none;
    }
    .location-area i {
        display: none;
    }
}

/********************  empl regis start ***************/

.registration {
    display: flex;
    align-content: stretch;
    width: 100%;
    margin-top: 1.5rem;

    font-size: 1.6rem;
    border-radius: 0.5rem;
    /*box-shadow: 0px 0px 9px -1px rgba(0,0,0,0.2);
	-webkit-box-shadow: 0px 0px 9px -1px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 9px -1px rgba(0,0,0,0.2); */
}

.regis-text {
    flex: 4;
    align-items: center;
    border: 1px solid #ffde6a;
    background-color: #fffefa;
    color: #686781;

    height: auto;

    border-radius: 0.5rem 0rem 0rem 0.5rem;
    padding: 3rem;
}
.reg-formcon {
    flex: 5;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1.5rem;
}

.unick-box {
    background-color: #fff;
    border: 1px solid #ffde6a;

    border-radius: 0.5rem;
    box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
}

.form-control,
.form-select {
    padding: 0.5rem 1rem;
    font-size: 1.6rem;
}
.col-form-label span.red-text {
    color: red;
    font-size: 1.6rem;
    position: unset;
    top: 0;
}

.btn {
    font-size: 1.6rem;
    min-width: 15rem;
    padding: 1rem;
    border-radius: 0.5rem;
    text-transform: uppercase;
}

.btn-auto {
    font-size: 1.6rem;
    min-width: auto;
    padding: 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    display: inline-block;
}

.btn-auto-pros {
    font-size: 1.2rem;
    min-width: auto;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    display: inline-block;
}

.badge {
    min-width: 15rem;
    padding: 1rem !important;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: inherit;
}
.pl-0 {
    padding-left: 0px;
}

h1 {
    font-size: 4.1rem;
    font-weight: 700 !important;
}

h2 {
    font-size: 2.6rem;
    padding-bottom: 0.5rem;
    font-weight: 700;
}
.yellow-text {
    color: #fbaa1c;
}
.hero-inner {
    background: #ffffef;
    border-top: 1px solid #fcb333;
    border-bottom: 1px solid #fcb333;
    display: none;
}

.hero-inner h1 {
    font-size: 4rem;
    font-weight: 700 !important;
    color: #000;
}

.btn-secondary {
    color: #000;
    background-color: #f5dd10;
    border-color: #f8df11;
}
.btn-secondary:hover {
    color: #fff;
    background-color: #fba816;
    border-color: #fba816;
}

@media (max-width: 1024px) {
    .registration {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .registration {
        flex-direction: column;
    }
    .regis-text {
        order: 2;
        border-radius: 0rem 0rem 0.5rem 0.5rem;
        border-top: none;
    }
    .reg-formcon {
        order: 1;
        border-radius: 0.5rem 0.5rem 0rem 0rem;
    }
    .pt-mob-3 {
        margin-top: 1rem;
    }
}

/********************  empl jib list start ***************/

.employer-job-list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;
}
.job-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid #0d6efd;
    margin-bottom: 1.5rem;
    background: #fff;
}
.br-5 {
    border-radius: 0.5rem;
}
.card h2 {
    margin-bottom: 0;
}

.job-list:hover {
    border: 1px solid #0d6efd;
    background: #d4e5ff;
}
.job-list div {
    font-size: 1.6rem;
}
.job-list div p {
    margin-bottom: 0rem;
}
.job-title {
    /* font-size: 2rem !important;*/
    font-weight: 700;
}
.job-pos {
    flex: 5;
}
.job-qus {
    flex: 2;
}
.job-act {
    flex: 3;
    align-items: flex-end;
    text-align: right;
}

.doc-upload {
    flex: 3;
}
.doc-status {
    flex: 2;
    text-align: right;
}
.doc-status span {
    top: 0;
}
.btn-job {
    text-decoration: none;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
}
.btn-link:hover {
    font-weight: normal;
    color: #000;
    text-decoration: none;
}

.pagination {
    font-size: 1.4rem;
}
.page-link {
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
}
@media (max-width: 767px) {
    .job-list {
        display: flex;
        flex-direction: column;
    }
    .job-qus {
        margin: 0.5rem 0 1.5rem 0rem;
    }
    .doc-status {
        text-align: center;
    }
    .job-list div {
        margin: 0.5rem 0;
        font-size: 1.6rem !important;
    }
    .employer-pros-list-content-con {
        flex-direction: column;
    }
}

/********************  empl pros list start ***************/

.employer-pros-list-content {
    display: flex;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffde6a;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    margin-top: 1.5rem;
}

.employer-pros-list-content-con {
    display: flex;
    width: 100%;
}
.employer-pros-list-content-left {
    flex: 1;
}
.employer-pros-list-content-right {
    flex: 4;
}

.jobvacancy-con {
    display: flex;
    flex-direction: column;
    margin: 0.7rem 0;
}

.jobvacancy-box {
    display: flex;
    flex-direction: column;
    border: #0061ff 1px solid;
    padding: 1.5rem;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    transition: 0.4s;
    margin: 0.8rem 0;
}

.jobvacancy-box:hover {
    border: 1px solid #ffde6a;
    background-color: #fffefa;
    color: #686781;
}

.jobvacancy-box h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: 700;
}
span {
    font-size: 1.5rem;
    position: relative;
    top: 0px;
}
.jobvacancy-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px solid #ccc;
}
.jobvacancy-box ul li {
    list-style: none;
    margin: 0.5rem 0;
}
.jobvacancy-box ul li strong {
    font-weight: 600;
}

.btn-search {
    border-radius: 0rem 0.3rem 0.3rem 0;
    min-width: 8rem;
}

.job-seeker-con {
    display: flex;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffde6a;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin: 1.5rem 0;
    font-size: 1.6rem;
    flex-direction: column;
}
.top {
    display: flex;
}
.left {
    display: flex;
    flex-direction: column;
    flex: 3;
}
.left div h3 {
    font-size: 2rem;
    font-weight: 500;
}

.left div h3 span {
    position: unset;
    top: 0;
    font-size: 2rem;
    font-weight: 500;
}
.pro {
    flex: 4;
}
.pro ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 0.5rem;
}
.pro ul li {
    margin: 0.3rem 0rem;
}
.left div h4 {
    font-size: 1.8rem;
    font-weight: 700;
}
.emp-con {
    display: flex;
    margin: 1.5rem 1.5rem 1.5rem 0rem;
}
.emp-con-pro {
    flex: 2;
}
.pre-time-com {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1.5rem;
}
.pre-time-com div {
    text-align: center;
    padding: 1rem 0;
    width: 49%;
    border-radius: 2.5rem;
}
.green-border {
    border: 1px solid green;
    color: green;
}
.dark-border {
    border: 1px solid #000;
    color: #000;
}
.sky-border {
    border: 1px solid #0dcaf0;
}
.white-text {
    color: #fff;
}

.avil-com {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-right: 1.5rem;
}
.avil-com div {
    text-align: center;
    padding: 1rem 0;
    width: 49%;
    border-radius: 2.5rem;
}

.right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1.5rem;
}

.bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
}
.bot div {
    width: 49%;
    text-align: center;
}
.bot div a {
    text-align: center;
    width: 100%;
    text-decoration: none;
    float: left;
    padding: 1rem;
    border-radius: 2.5rem;
}

.pt-0 {
    padding-top: 0 !important;
}
.ml-15 {
    padding-left: 1.5rem;
}

.btn-group-cus {
    vertical-align: middle;
    margin: 0 auto 1.5rem;
}

/********************  empl pros list end ***************/

.card-cus {
    border: 1px solid #ffde6a;
    background-color: #fffefa;
    color: #686781;
    border-radius: 0.5rem;
    padding: 1.5rem;
}

.card-cus h2 span {
    font-size: 1.4rem;
    display: block;
    font-weight: 500;
    color: green;
    margin-top: 1rem;
}
.card-cus li {
    margin: 0.4rem 0;
}
.card-cus li a i {
    color: #111;
}
.card-cus a {
    font-size: 1.6rem;
    text-decoration: none;
}
.mt-15 {
    margin-top: 0.8rem;
}

.card-cus li span {
    font-size: 1.6rem;
    position: relative;
    top: 0;
}

.btn-block {
    width: 100% !important;
}
.card-cus-blue {
    border: 1px solid #0061ff;
    background-color: #fff;
}

.dropdown-item {
    padding: 0.5rem 1rem;
    font-weight: 400;
    color: #212529;
    font-size: 1.6rem;
}

.dropdown-item:hover {
    background: #fcd5a3;
}

.inter-view {
    padding: 1.5rem;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid #0d6efd;
    margin-top: 1.5rem;
    background: #fff;
    font-size: 1.6rem !important;
}

.inter-view:hover {
    border: 1px solid #0d6efd;
    background: #d4e5ff;
}
.inter-view span {
    top: 0 !important;
}

.modal-header h2 {
    padding-bottom: 0;
}

.form-check-label,
.form-label {
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
}
.form-label span {
    top: 0;
    color: red;
}
.text-yellow {
    color: #ffc107;
}

.form-check-input-modal {
    width: 1.6rem;
    height: 1.6rem;
    margin-top: 00.6rem;
    margin-right: 1rem;
}

@media (max-width: 640px) {
    .min-vw-25 {
        width: 1vw !important;
    }
}

.unick-box-sha {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
    .employer-pros-list-content-con {
        flex-direction: column;
    }
    .profile-image {
        width: 40%;
        text-align: center;
        border-radius: 25%;
    }
}
.modal {
    background: rgba(61, 61, 61, 0.9);
}
.sky-one {
    background-color: #d8e2f3;
    color: #000;
}
.sky-one:hover {
    background-color: #b3c6e7;
    color: #000;
}

.sky-two {
    background-color: #b3c6e7;
    color: #000;
}
.sky-two:hover {
    background-color: #8da9bd;
    color: #000;
}

.sky-three {
    background-color: #8da9bd;
    color: #000;
}
.sky-three:hover {
    background-color: #d8e2f3;
    color: #000;
}

.sky-four {
    background-color: #4472c4;
    color: #000;
}
.sky-four:hover {
    background-color: #2a56a5;
    color: #000;
}

.sky-four-white {
    background-color: #4472c4;
    color: #fff;
}
.sky-four-white:hover {
    background-color: #2a56a5;
    color: #fff;
}

.green-one {
    background-color: #70ad47;
    color: #000;
}
.green-one:hover {
    background-color: #609a39;
    color: #000;
}

.ass-one {
    background-color: #aeabab;
    color: #000;
}
.ass-one:hover {
    background-color: #9a9999;
    color: #000;
}

.ass-two {
    background-color: #757070;
    color: #000;
}
.ass-two:hover {
    background-color: #545454;
    color: #fff;
}

.ass-two-white {
    background-color: #757070;
    color: #fff;
}
.ass-two-white:hover {
    background-color: #545454;
    color: #fff;
}

.sky-modal {
    background-color: #4472c4;
    color: #fff;
}
.sky-modal:hover {
    background-color: #2a56a5;
    color: #fff;
}

.modal-content {
    background-color: transparent !important;
    border: none;
}
.modal-header {
    padding: 0rem 1rem;
    border-bottom: none;
}
.card {
    border-radius: 0.5rem;
}

@media (max-width: 767px) {
    .card-sm-center {
        text-align: center;
    }
    .card-sm-center .rounded {
        border-radius: 50% !important;
    }
    .mt-2-sm {
        margin-top: 0.5rem;
    }
}

.link-dark {
    color: #212529;
    text-decoration: none;
}

/***************** profile start **************/

.mt-1-5 {
    margin-top: 1.5rem;
}
.pr-mi-hi {
    min-height: 12rem;
}
.pr-mi-hi-sm {
    min-height: 5.2rem;
}
.pro-card-shadow {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}

.pro-head {
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
}
/*.link-list li::after {
	content: "|";
	padding-left: 0.8rem;
	font-size: 1.2rem;
	line-height: 1.2rem;
	position: relative;
	top: -1px;
}*/

.link-list li:not(:last-child):after {
    content: '|';
    padding-left: 0.8rem;
    font-size: 1.2rem;

    position: relative;
    top: -0.2rem;
}

.li-badge {
    background: #eee;
    padding: 1rem 2rem;
    border-radius: 0.4rem;
    margin: 1rem 1rem 0 0;
    color: #666;
}
.p-abso {
    position: relative;
    width: 100%;
    height: 100%;
}
.p-abso-edit a {
    position: absolute;
    width: auto;
    height: auto;
    z-index: 99;
    padding: 0.5rem;
    background: #2a56a5;
    right: 0;
    bottom: 1rem;
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 0.3rem;
}

.p-abso-edit a:hover {
    background: #4472c4;
    color: #fff;
    text-decoration: none;
}

.white-one {
    background-color: #fff;
    color: #4472c4;
}
.white-one:hover {
    background-color: #4472c4;
    color: #fff;
}

.font-sm {
    font-size: 1.3rem !important;
}

.form-check-input {
    width: 1.6em;
    height: 1.6em;
    margin-top: 0.45em;
}

.employer-dropdown:hover .employer-dropdown-menu {
    display: block;
    border-top: 0.1875rem solid #f6931c;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.span_info_subhr {
    top: -0.2rem !important;
    left: 0.8rem !important;
}
/***************** profile end **************/

.static-content-box ul li a.ar-link,
.static-content-box ul li a.subar-link {
    text-decoration: none;
    color: #424242;
    position: relative;
    padding: 8px 6px;
    display: inline-block;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
}

.static-content-box ul li {
    color: #505050;
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 22px;
    list-style: none;
}

.static-content-box {
    margin-top: 30px;
}
.static-content-box ul {
    margin-bottom: 20px;
}

.static-content-box ul li a.ar-link:before,
.static-content-box ul li a.subar-link:before {
    content: '\203A';
    position: absolute;
    left: 0;
}

.accordion-item:not(:first-of-type) {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}
