.inter-view:hover {
    cursor: pointer;
}

.interview-active {
    background: #d5d5d5;
}

.del-btn {
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding: 0;
}

.interview-utils {
    font-size: 12px;
}
